.App {
  /* scroll-snap-type: mandatory; */
  /* scroll-snap-type: y mandatory; */
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  background-color: #282c34;
  min-height: 100px;
  width: 100%; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px; 
  color: white;
}

.full-height {
  min-height: 100vh; 
  /* scroll-snap-align: start; */
}

.page.full-height {
  min-height: calc(100vh - 280px); 
  /* min-height: 90vh; */
  margin: 25px auto; 
}

.App-link {
  color: #61dafb;
}



.center {
  text-align: center; 
}


.submission-info {
  max-width: 75vw; 
  margin: 0 auto; 
  padding: 5px 50px; 
  color: #282c34;
  text-align: left; 
}

.tabs {
  display: flex; 
  align-items: center;
  justify-content: space-evenly;
  min-width: 50%; 
  max-width: 80%; 
  margin: 30px auto 0 auto; 
}



.tab.small {
  width: 20%; 
  margin: 10px auto; 
  justify-content: space-evenly;
}

.tab-text{
  cursor: pointer; 
  position: relative; 
  /* text-decoration: underline;
  text-underline-offset: 5px; */
  text-decoration: none; 
  text-align: center; 
}

/* .tab:hover {

} */

.tab-text::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  /* background-color: #282c34;  */
  transform: scaleX(0);
  transition: transform 0.3s ease;
}
.tab-text.small::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  background-color: #282c34; 
  transform: scaleX(0);
  transition: transform 0.3s ease;
}
.tab-text:hover::before {
  transform: scaleX(1);
}

.tab-text.active {
  /* content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform: scaleX(1); */
  text-decoration: underline;
  text-underline-offset: 12px;
  text-decoration-line: 2px underline;
}

.button {
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid #282c34;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  /* font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif; */
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  line-height: 1.25;
  margin: 15px 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button:hover,
.button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button:hover {
  cursor: pointer; 
  transform: translateY(-1px);
}

.button:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.animate-header-shrink {
  /* animation-delay: 1s; */
  animation: header-shrink 1.5s ease-in; 
  
}

/* .regular {
  visibility: visible; 
} */
.header-text {
  height: 0px; 
  opacity: 0; 
  padding: 0; 
  visibility: hidden; 
}

.pre-fade {
  height: inherit; 
  opacity: 1; 
  padding: inherit; 
  visibility: visible;
}


.animate-fade-out {
  animation: fade-out 0.25s ease-out; 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes header-shrink {
  0% {
    min-height: 100vh; 
  }
  25% {
    min-height: 100vh;
  }
  100% {
    min-height: 100px; 
  }
}

@keyframes fade-out {
  0% {
    opacity: 1; 
    height: inherit; 
    padding: inherit; 
    visibility: visible;
  }
  100% {
    height: 0px; 
    opacity: 0; 
    padding: 0; 
    visibility:  hidden; 
  }
}

.footer {
  background-color: #282c34; 
  color: white; 
  /* position: relative; 
  bottom: 0;  */
  width: 100%; 
  min-height: 100px; 
  /* margin-bottom: -5px;  */
  padding: 10px 0; 
  text-align: left; 
}

.footer-section {
  width: 25%; 
}

.footer-container {
  max-width: 70vw; 
  margin: 0 auto; 
  display: flex; 
  justify-content: space-around;
}

.footer-text {
  font-size: 12px; 
}

.footer a {
  color: white; 
  text-underline-offset: 4px;
}

.footer-container .footer-section {
  margin-bottom: 10px; 
}

@media (max-width: 980px) {
  .tabs {
    min-width: 80%; 
  }
}

@media (max-width: 750px) {
  .tabs {
    min-width: 100%; 
  }

  .page {
    min-width: 100%; 
  }

  .submission-info {
    min-width: 100%; 
  }

  .center {
    text-align: left;
  }
  .button-container {
    text-align: center; 
  }

  .footer-container {
    flex-direction: column; 
    align-items: center;
    text-align: center;
    width: 100%; 
  }

  .footer-container .footer-section {
    width: 100%; 
  }
}

@media (max-width: 650px) {
  .tabs {
    min-width: 100%; 
    flex-direction: column; 
  }
}