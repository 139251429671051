@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&family=Roboto:wght@500;700&display=swap');
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Oswald',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex; 
  flex-wrap: wrap; 
}

.flex.row {
  flex-direction: row;
  justify-content: space-around;
  align-items: center; 
}
.flex.column {
  flex-direction: column;
}

h1, h2 {
  font-family: 'Oswald', sans-serif;
  padding: 10px 0px; 
}

h3, h4, h5, p {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 70px;
  text-transform: uppercase;
}

h2 {
  font-size: 50px;
  text-transform: uppercase;
}

h3 {
  font-size: 32px; 
  text-transform: uppercase;
  padding: 10px 0; 
}

h4 {
  font-size: 24px;
  margin: 0px; 
  text-transform: uppercase;
  padding: 10px 0; 
}

p {
  font-size: 16px; 
  line-height: 1.5;
  margin: 10px 0; 
}

ul {
  font-family: 'Roboto', sans-serif;
  font-weight: 500; 
}

li {
  line-height: 1.5; 
  /* margin-bottom: 3px; */
}

li.bullet {
  list-style-position: inside;
  list-style-type: disc;
}
li.nested-list {
  list-style-position: inside;
  list-style-type: "→";
  margin-left: 20px; 
}
.underline {
  text-decoration: underline;
  text-decoration-color: #282c34;
  text-underline-offset: 5px;
}
.submission-detail {
  width: 100%; 
}

